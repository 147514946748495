import React, { useState, useCallback, useEffect } from 'react';
import { render } from 'react-dom';
import Table from 'react-bootstrap/Table';
import {Helmet} from "react-helmet";

export default function StampDutyBreakdownGrid({stampDutyBreakdown}){
return (
<div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Grid showing Stamp Duty Breakdown</title>
            </Helmet>
    <Table striped bordered hover>
        <thead>
            <tr>
                <th>House Price</th>
                <th>Total Stamp Duty</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{stampDutyBreakdown.housePrice}</td>
                <td>{stampDutyBreakdown.totalStampDuty}</td>
            </tr>
        </tbody>
    </Table>
    <br/>
    <Table striped bordered hover>
        <thead>
            <tr>
            <th>Tax Band</th>
            <th>Percent Paid at Tax Band</th>
            <th>House Price amount in Tax Band</th>
            <th>Stamp Duty paid at tax band</th>
            </tr>
        </thead>
        <tbody>
            {stampDutyBreakdown.taxBandBreakdowns.map(function(taxBandBreakdown, i){
                return (
                        <tr>
                            <td>{taxBandBreakdown.taxBandLabel}</td>
                            <td>{taxBandBreakdown.percentPaidAtTaxBand}</td>
                            <td>{taxBandBreakdown.housePriceAmountInTaxBandRange}</td>
                            <td>{taxBandBreakdown.stampDutyAmountToPayAtTaxBand}</td>
                        </tr>
                        )
            })}
        </tbody>
        </Table>
         <br/>
        <br/>
        <br/>
        </div>

)
}