import React, { useState, useCallback, useEffect } from 'react';

import { render } from 'react-dom';

import Table from 'react-bootstrap/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";

export default function TaxCalculationBreakdownGrid({taxBreakdown}){
return (
<div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Take Home Pay Breakdown</title>
            </Helmet>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Yearly</th>
                            <th>Monthly</th>
                            <th>Weekly</th>
                            <th>Daily</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Gross Income &nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} title="Salary before tax and other deductions"/>
                            </td>
                            <td>{taxBreakdown.yearlyDetails ? taxBreakdown.yearlyDetails.grossIncome : 0}</td>
                            <td>{taxBreakdown.monthlyDetails ? taxBreakdown.monthlyDetails.grossIncome: 0}</td>
                            <td>{taxBreakdown.weeklyDetails ? taxBreakdown.weeklyDetails.grossIncome: 0}</td>
                            <td>{taxBreakdown.dailyDetails ? taxBreakdown.dailyDetails.grossIncome: 0}</td>
                        </tr>

                        <tr>
                            <td>Taxable Income &nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} title="Amount of your salary which is taxed. (Amount of Gross Income above tax-free personal allowance)"/>
                            </td>
                            <td>{taxBreakdown.yearlyDetails ? taxBreakdown.yearlyDetails.taxableIncome : 0}</td>
                            <td>{taxBreakdown.monthlyDetails ? taxBreakdown.monthlyDetails.taxableIncome: 0}</td>
                            <td>{taxBreakdown.weeklyDetails ? taxBreakdown.weeklyDetails.taxableIncome: 0}</td>
                            <td>{taxBreakdown.dailyDetails ? taxBreakdown.dailyDetails.taxableIncome: 0}</td>
                        </tr>
                          <tr>
                              <td>Income Tax &nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} title="Tax on your income"/>
                              </td>
                              <td>{taxBreakdown.yearlyDetails ? taxBreakdown.yearlyDetails.incomeTax : 0}</td>
                              <td>{taxBreakdown.monthlyDetails ? taxBreakdown.monthlyDetails.incomeTax: 0}</td>
                              <td>{taxBreakdown.weeklyDetails ? taxBreakdown.weeklyDetails.incomeTax: 0}</td>
                              <td>{taxBreakdown.dailyDetails ? taxBreakdown.dailyDetails.incomeTax: 0}</td>
                          </tr>

                          <tr>
                              <td>National Insurance &nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} title="National insurance contributions are used to pay for certain state benefits such as pensions and NHS services"/>
                              </td>
                              <td>{taxBreakdown.yearlyDetails ? taxBreakdown.yearlyDetails.nationalInsurance : 0}</td>
                              <td>{taxBreakdown.monthlyDetails ? taxBreakdown.monthlyDetails.nationalInsurance: 0}</td>
                              <td>{taxBreakdown.weeklyDetails ? taxBreakdown.weeklyDetails.nationalInsurance: 0}</td>
                              <td>{taxBreakdown.dailyDetails ? taxBreakdown.dailyDetails.nationalInsurance: 0}</td>
                          </tr>

                            <tr>
                                <td>Student Loan &nbsp;
                                  <FontAwesomeIcon icon={faInfoCircle} title="Deductions for paying student loans"/>
                                </td>
                                <td>{taxBreakdown.yearlyDetails ? taxBreakdown.yearlyDetails.studentLoanPayment : 0}</td>
                                <td>{taxBreakdown.monthlyDetails ? taxBreakdown.monthlyDetails.studentLoanPayment: 0}</td>
                                <td>{taxBreakdown.weeklyDetails ? taxBreakdown.weeklyDetails.studentLoanPayment: 0}</td>
                                <td>{taxBreakdown.dailyDetails ? taxBreakdown.dailyDetails.studentLoanPayment: 0}</td>
                            </tr>

                        <tr>
                            <td>Total Deductions &nbsp;
                              <FontAwesomeIcon icon={faInfoCircle} title="The total sum of all deductions on your salary (Income tax, National Insurance, Student Loan)"/>
                            </td>
                            <td>{taxBreakdown.yearlyDetails ? taxBreakdown.yearlyDetails.totalDeductions : 0}</td>
                            <td>{taxBreakdown.monthlyDetails ? taxBreakdown.monthlyDetails.totalDeductions: 0}</td>
                            <td>{taxBreakdown.weeklyDetails ? taxBreakdown.weeklyDetails.totalDeductions: 0}</td>
                            <td>{taxBreakdown.dailyDetails ? taxBreakdown.dailyDetails.totalDeductions: 0}</td>
                        </tr>

                          <tr>
                               <td><b>Take Home Pay</b> &nbsp;
                                 <FontAwesomeIcon icon={faInfoCircle} title="Take home pay after all deductions"/>
                               </td>
                              <td><b>{taxBreakdown.yearlyDetails ? taxBreakdown.yearlyDetails.takeHomePay : 0}</b></td>
                              <td><b>{taxBreakdown.monthlyDetails ? taxBreakdown.monthlyDetails.takeHomePay: 0}</b></td>
                              <td><b>{taxBreakdown.weeklyDetails ? taxBreakdown.weeklyDetails.takeHomePay: 0}</b></td>
                              <td><b>{taxBreakdown.dailyDetails ? taxBreakdown.dailyDetails.takeHomePay: 0}</b></td>
                          </tr>
                    </tbody>
                </Table>
                <br/>
                <br/>
                <br/>
                  </div>
                )
}