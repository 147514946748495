import React, { useState, useCallback, useEffect } from 'react';

import { Form, Button, InputGroup } from 'react-bootstrap';
import { ArrowRight, CurrencyPound } from 'react-bootstrap-icons';
import DatePicker  from 'react-datepicker';
import StudentLoanRepaymentScheduleBreakdownGrid from './StudentLoanRepaymentScheduleBreakdownGrid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function StudentFinanceRepaymentCalculator(){

    console.log('Student Finance repayment Page')
  const [state, setState] = useState('');

  const [ studentFinanceRepaymentResults, setStudentFinanceRepaymentResults ] = useState({})
  const [showStudentLoanRepaymentSchedule, setShowStudentLoanRepaymentSchedule] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
     //this.state = {value: ''};
      //this.handleSubmit = this.handleSubmit.bind(this);

  const successNotification = () => toast.success("Calculation complete", {position: "top-right", autoClose: 5000, theme: "colored"});
  const errorNotification = () => toast.error("Calculation error", {position: "top-right", autoClose: 5000, theme: "colored"});

       const handleInputChange = (event) => {
       console.log('handle change')
           event.preventDefault();
              const target = event.target;
              const value = target.type === 'checkbox' ? target.checked : target.value;
              const name = target.name;
              console.log('name: ' + name + ', value: ' + value)
              setState(prevState => ({
                    ...prevState,
                    [name]: value
                }));
                  console.log('current state: ' + JSON.stringify(state))

       }

       const handleSubmit = async (event) => {
            event.preventDefault();
             console.log('Submitting form');


            try {
               const requestOptions = {
                       method: 'POST',
                       headers: { 'Content-Type': 'application/json' },
                       body: JSON.stringify(state)
                   };
                 // Need a URL Service or component
                 let res = await fetch("/studentLoanRepayment/studentLoanRepayment", requestOptions);
                 let resJson = await res.json();
                 if (res.status === 200) {
                     console.log('success: ' + JSON.stringify(resJson))
                     successNotification();
                     setStudentFinanceRepaymentResults(resJson)
                     setShowStudentLoanRepaymentSchedule(true)

                 } else {
                     console.log('error: ' + JSON.stringify(resJson))
                     errorNotification();
                 }
               } catch (err) {
                 console.log('Response error: ' + err);
                 errorNotification();
               }



       }
      //this.handleSubmit.bind(this)

    return (
    <>
       <h1>Student Finance Repayment Calculator</h1>
                   <Helmet>
                       <meta charSet="utf-8" />
                       <title>Student Finance Repayment Calculator</title>
                   </Helmet>
       <br/>

    <div className="mx-auto" style={{width: '400px'}} onSubmit={handleSubmit} method="POST">
            <Form>

                <Form.Label htmlFor="basic-url">Annual Salary Before Tax &nbsp;
                <FontAwesomeIcon icon={faInfoCircle} title="Input your annual salary before tax" />  </Form.Label>
                 <InputGroup className="mb-4">
                   <InputGroup.Text id="pound-addon-1">
                     £
                   </InputGroup.Text>
                   <Form.Control type="number" min="0" id="salaryBeforeTax" name="salaryBeforeTax" title="Input your annual salary before tax"
                   aria-describedby="pound-addon-1" onChange={handleInputChange} required></Form.Control>
                 </InputGroup>

                 <Form.Label htmlFor="outstandingStudentLoanAmount">Outstanding Loan Amount &nbsp;
                 <FontAwesomeIcon icon={faInfoCircle} title="Input the amount of student loan which still needs to be paid off" /> </Form.Label>
                  <InputGroup className="mb-4">
                    <InputGroup.Text id="pound-addon-2">
                      £
                    </InputGroup.Text>
                    <Form.Control type="number" min="0" id="outstandingStudentLoanAmount" name="outstandingStudentLoanAmount" title="Input the amount of student loan which still needs to be paid off"
                     aria-describedby="pound-addon-2" onChange={handleInputChange} required></Form.Control>
                  </InputGroup>

                   <Form.Group className="mb-3">
                         <Form.Label htmlFor="disabledSelect" >Type of Student Loan &nbsp;
                         <FontAwesomeIcon icon={faInfoCircle} title="Select the type of student loan" />
                         </Form.Label>
                         <Form.Select id="studentLoanPlanType" name="studentLoanPlanType" onChange={handleInputChange} defaultValue="PLAN_1" title="Select the type of student loan">
                             <option value="PLAN_1">Plan 1 (England & Wales students before 2012)</option>
                             <option value="PLAN_2">Plan 2 (England & Wales students after September 2012, before July 2023)</option>
                             <option value="PLAN_4">Plan 4 (Scottish students)</option>
                             <option value="PLAN_5">Plan 5 (England & Wales students after 2023)</option>
                             <option value="POSTGRADUATE">Postgraduate Loan</option>
                         </Form.Select>
                       </Form.Group>

                      <Form.Group className="mb-3">
                       <Form.Label htmlFor="loanStartYear" >Loan Start Year &nbsp;
                        <FontAwesomeIcon icon={faInfoCircle} title="Select the year in which you took out your loan. This is used to calculate in what year the loan will be written off" />
                       </Form.Label>
                        <InputGroup className="mb-4">
                         <InputGroup.Text id="calendar-addon-1">
                             <span class="glyphicon glyphicon-calendar"></span>
                              <FontAwesomeIcon icon={faCalendar} />

                        </InputGroup.Text>
                       <Form.Control type="number" id="loanStartYear" name="loanStartYear" title="Select the year in which you took out your loan. This is used to calculate in what year the loan will be written off"
                                            aria-describedby="calendar-addon-1" defaultValue="2023" onChange={handleInputChange} required></Form.Control>
                        </InputGroup>
                      </Form.Group>

                        <Form.Label htmlFor="annualPayRise">Annual Pay Rise &nbsp;
                         <FontAwesomeIcon icon={faInfoCircle} title="Enter an estimated annual pay rise" />
                        </Form.Label>
                         <InputGroup className="mb-4">
                           <InputGroup.Text id="percentage-addon-1">
                             %
                           </InputGroup.Text>
                           <Form.Control type="number" min="0" id="annualPayRise" name="annualPayRise" title="Enter an estimated annual pay rise"
                            aria-describedby="percentage-addon-1" defaultValue="0" onChange={handleInputChange} required></Form.Control>
                         </InputGroup>

                   <Button variant="secondary" type="submit">
                     Submit
                   </Button>
                 </Form>
    </div>






         <br/>
          <ToastContainer />
          { showStudentLoanRepaymentSchedule ? <StudentLoanRepaymentScheduleBreakdownGrid studentLoanRepaymentScheduleBreakdown={studentFinanceRepaymentResults} /> : null }
    </>
    )
}