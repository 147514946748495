import React, { useState, useEffect } from 'react';

import { render } from 'react-dom';

import Table from 'react-bootstrap/Table';

import StampDutyBreakdownGrid from './StampDutyBreakdownGrid'
import { Form, Button, InputGroup } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function StampDutyCalculator(){
    function numberFormatter(params) {
    if(params && params.value){
        return Number(params.value).toLocaleString()
    }
      return ''
    }

  const successNotification = () => toast.success("Calculation complete", {position: "top-right", autoClose: 5000, theme: "colored"});
  const errorNotification = () => toast.error("Calculation error", {position: "top-right", autoClose: 5000, theme: "colored"});

    console.log('v4 bootstrap')
     const [ housePrice, setHousePrice ] = useState("");
     const [ stampDutyBreakdown, setStampDutyBreakdown ] = useState({})

     const [count, setCount] = useState(0);

     const [showStampDutyBreakdownResponse, setShowStampDutyBreakdownResponse] = useState(false);

     useEffect(() => {
        console.log('stamp duty breakdown', stampDutyBreakdown);
        //setShowTaxCalculationResponse(true);
     }, [stampDutyBreakdown])

       let handleSubmit = async (e) => {
        console.log('handle submit: ' + housePrice)
          e.preventDefault();
          try {
          const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ housePrice: housePrice })
              };

            let res = await fetch("/stampDuty/stampDutyBreakdown", requestOptions);
            let resJson = await res.json();
            if (res.status === 200) {
                console.log('success: ' + JSON.stringify(resJson))

                successNotification();

                setShowStampDutyBreakdownResponse(true);
                setStampDutyBreakdown(resJson);

            } else {
                console.log('error: ' + JSON.stringify(resJson))
                errorNotification();
            }
          } catch (err) {
            console.log("Error occurred")
            console.log(err);
            errorNotification();
          }
        };

    return (
    <>
        <h1>Stamp Duty Calculator</h1>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Stamp Duty Calculator</title>
            </Helmet>
        <div class="mx-auto" style={{width: '400px'}} onSubmit={handleSubmit} method="POST">
            <Form >
            <Form.Label htmlFor="housePrice">House Price  &nbsp;
              <FontAwesomeIcon icon={faInfoCircle} title="Input house price" />
            </Form.Label>
             <InputGroup className="mb-4">
               <InputGroup.Text id="pound-addon-1">
                 £
               </InputGroup.Text>
               <Form.Control type="number" id="housePrice" min="0" value={housePrice} name="housePrice" aria-describedby="pound-addon-1" onChange={(e) => setHousePrice(e.target.value)} required></Form.Control>
             </InputGroup>

           <Button variant="dark" type="submit">
             Submit
           </Button>
         </Form>
         <br/>
        </div>
        <ToastContainer />
         { showStampDutyBreakdownResponse ? <StampDutyBreakdownGrid stampDutyBreakdown={stampDutyBreakdown} /> : null }
    </>
    )

}