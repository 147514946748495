import { Link, useMatch, useResolvedPath } from "react-router-dom"
import React from 'react';

export default function Navbar(){

    return <nav className="nav">
        <Link to="/" className="site-title">UK Finance Calculators</Link>
        <ul>
            <CustomLink to="/takeHomePayCalculator">Take Home Pay Calculator</CustomLink>
            <CustomLink to="/studentFinanceRepayment">Student Finance Repayment</CustomLink>
            <CustomLink to="/stampDutyCalculator">Stamp Duty Calculator</CustomLink>
            <Link to="https://www.buymeacoffee.com/rurounisergii" target="_blank">Support the Site</Link>
            <Link to="#"
                  onClick={() => window.location = 'mailto:ukfinancecalculators@gmail.com'}>
              Contact Me
            </Link>
        </ul>
    </nav>
}

function CustomLink({to, children, ...props}){
    const resolvedPath = useResolvedPath(to)
    console.log('to: ' + to + ', resolved path: ' + JSON.stringify(resolvedPath))
    // end: true -> exact matches only /pricing but not /pricing/todos
    const isActive = useMatch({path: resolvedPath.pathname, end: true})
    const path = window.location.pathname
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} >{children}</Link>
        </li>
    )
}

