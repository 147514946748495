import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear();

  return <>
  <br/>
  <br/>
  <footer>{
  `The output of this tool is only intended as a guide and should not be taken as financial advice.
  Please check the UK government website or contact a financial advisor before making any investment decision.
   Copyright © rurounisergii ${year}`}
   </footer>;
   </>
};

export default Footer;