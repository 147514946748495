import React, { useState, useCallback, useEffect } from 'react';
import { render } from 'react-dom';

import Table from 'react-bootstrap/Table';
import { getBackendUrl } from '../service/UrlService';
import { numberFormatter } from '../service/FormatService'

import TaxCalculationBreakdownGrid from './TaxCalculationBreakdownGrid'
import { Form, Button, InputGroup } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function TakeHomePayCalculator(){

 const [state, setState] = useState('');
           const handleInputChange = (event) => {
           console.log('Input updated')
               event.preventDefault();
                  const target = event.target;
                  const value = target.type === 'checkbox' ? target.checked : target.value;
                  const name = target.name;
                  console.log('name: ' + name + ', value: ' + value)
                  setState(prevState => ({
                        ...prevState,
                        [name]: value
                    }));
                      console.log('current state: ' + JSON.stringify(state))

           }

  const successNotification = () => toast.success("Calculation complete", {position: "top-right", autoClose: 5000, theme: "colored"});
  const errorNotification = () => toast.error("Calculation error", {position: "top-right", autoClose: 5000, theme: "colored"});

     // The salary input by the user
     const [ salary, setSalary ] = useState("");
     //
     const [ taxBreakdown, setTaxBreakdown ] = useState({})
     const [count, setCount] = useState(0);
     const [showTaxCalculationResponse, setShowTaxCalculationResponse] = useState(false);

     useEffect(() => {
        console.log('taxBreakdown', taxBreakdown);
        //setShowTaxCalculationResponse(true);
     }, [taxBreakdown])

       let handleSubmit = async (e) => {
        console.log('handle submit: ' + salary)
          e.preventDefault();
          try {
          const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(state)
              };

            let res = await fetch(getBackendUrl() + "tax/taxCalculation", requestOptions);
            let resJson = await res.json();
            if (res.status === 200) {
                console.log('success: ' + JSON.stringify(resJson))
                successNotification();
                setShowTaxCalculationResponse(true);
                setTaxBreakdown(resJson);

            } else {
                console.log('error: ' + JSON.stringify(resJson))
                errorNotification();
            }
          } catch (err) {
            console.log(err);
            errorNotification();
          }
        };

    return (
    <>
        <h1>Take Home Pay Calculator</h1>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Take Home Pay Calculator</title>
            </Helmet>
        <div class="mx-auto" style={{width: '400px'}} onSubmit={handleSubmit} method="POST">
            <Form >
            <Form.Label htmlFor="salaryBeforeTax">Salary before tax &nbsp;
             <FontAwesomeIcon icon={faInfoCircle} title="Input your annual salary before tax" />
            </Form.Label>
             <InputGroup className="mb-4">
               <InputGroup.Text id="pound-addon-1">
                 £
               </InputGroup.Text>
               <Form.Control type="number" min="0" id="salaryBeforeTax" name="salaryBeforeTax" aria-describedby="pound-addon-1" onChange={handleInputChange} required></Form.Control>
             </InputGroup>

            <Form.Group className="mb-3">
                  <Form.Label htmlFor="disabledSelect" >Type of Student Loan &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} title="Input the type of student loan you have" />
                  </Form.Label>
                  <Form.Select id="studentLoanPlanType" name="studentLoanPlanType" onChange={handleInputChange} defaultValue="NONE">
                      <option value="NONE">No Student Loan</option>
                      <option value="PLAN_1">Plan 1 (England & Wales students before 2012)</option>
                      <option value="PLAN_2">Plan 2 (England & Wales students after September 2012, before July 2023)</option>
                      <option value="PLAN_4">Plan 4 (Scottish students)</option>
                      <option value="PLAN_5">Plan 5 (England & Wales students after 2023)</option>
                      <option value="POSTGRADUATE">Postgraduate Loan</option>
                  </Form.Select>
                </Form.Group>
{
/**
              <Form.Label htmlFor="pensionContribution">Pension Contribution &nbsp;
                <FontAwesomeIcon icon={faInfoCircle} title="Input your annual pension contribution" />
              </Form.Label>
             <InputGroup className="mb-4">
               <InputGroup.Text id="percentage-addon-1">
                 %
               </InputGroup.Text>
               <Form.Control type="number" id="pensionContribution" name="pensionContribution" aria-describedby="percentage-addon-1" defaultValue="0" onChange={handleInputChange} required></Form.Control>
             </InputGroup>
             **/
}
           <Button variant="dark" type="submit">
             Submit
           </Button>
         </Form>
         <br/>
        </div>
        <ToastContainer />
         { showTaxCalculationResponse ? <TaxCalculationBreakdownGrid taxBreakdown={taxBreakdown} /> : null }
    </>
    )
}