import NavBar from "./Navbar"
import Footer from "./Footer"
import TakeHomePayCalculator from "./pages/TakeHomePayCalculator"
import React from 'react';
import StudentFinanceRepaymentCalculator from "./pages/StudentFinanceRepaymentCalculator"
import StampDutyCalculator from "./pages/StampDutyCalculator.js"
import { Route, Routes } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
    return (
        <>

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
          integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
          crossorigin="anonymous"
        />

            <NavBar/>
            <div className="container">
            <Routes>
                <Route path="/" element={<TakeHomePayCalculator/>} />
                <Route path="/takeHomePayCalculator" element={<TakeHomePayCalculator/>} />
                <Route path="/studentFinanceRepayment" element={<StudentFinanceRepaymentCalculator/>} />
                <Route path="/stampDutyCalculator" element={<StampDutyCalculator/>} />
                <Route path="/error" element={<TakeHomePayCalculator/>} />
                <Route path="/*" element={<TakeHomePayCalculator/>} />
            </Routes>
            </div>
            <br/>
            <br/>
            <Footer/>
        </>
    )
}

export default App