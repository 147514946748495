import React, { useState, useCallback, useEffect } from 'react';

import { render } from 'react-dom';

import Table from 'react-bootstrap/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";

export default function StudentLoanRepaymentScheduleBreakdownGrid({studentLoanRepaymentScheduleBreakdown}){
return (
<div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Student Finance Repayment Breakdown</title>
            </Helmet>
    <Table striped bordered hover>
        <thead>
            <tr>
                <th>Number of years to repay loan  &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} title="Number of years it will take to fully repay student loan"/>
                </th>
                <th>Loan Repayment Year &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} title="Year when student loan will be fully paid off"/>
                </th>
                <th>Total Repayment Amount &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} title="Total amount of money paid (loan amount + interest)"/>
                </th>
                <th>Interest Rate &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} title="Interest rate for the selected student loan plan type. Used to calculate the yearly amount of interest to add."/>
                </th>
                <th>Additional Information &nbsp;
                    <FontAwesomeIcon icon={faInfoCircle} title="Any additional information/commentary"/>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{studentLoanRepaymentScheduleBreakdown.numberOfYearsToRepay}</td>
                <td>{studentLoanRepaymentScheduleBreakdown.repaymentYear}</td>
                <td>{studentLoanRepaymentScheduleBreakdown.totalAmountRepaid}</td>
                <td>{studentLoanRepaymentScheduleBreakdown.interestRate}</td>
                <td>{studentLoanRepaymentScheduleBreakdown.comment}</td>
            </tr>
        </tbody>
    </Table>
    <br/>
    <Table striped bordered hover>
        <thead>
            <tr>
            <th>Year Number</th>
            <th>Year</th>
            <th>Salary Amount</th>
            <th>Outstanding Loan start of year</th>
            <th>Interest added</th>
            <th>Outstanding Loan + Interest</th>
            <th>Student Loan Payment</th>
            <th>Outstanding Loan end of year</th>
            <th>Salary Increase</th>
            </tr>
        </thead>
        <tbody>
            {studentLoanRepaymentScheduleBreakdown.yearlyRepaymentBreakdown.map(function(yearlyBreakdown, i){
                return (
                        <tr>
                            <td>{yearlyBreakdown.yearNumber}</td>
                            <td>{yearlyBreakdown.year}</td>
                            <td>{yearlyBreakdown.salaryAmount}</td>
                            <td>{yearlyBreakdown.outstandingLoanStartOfYear}</td>
                            <td>{yearlyBreakdown.interestAdded}</td>
                            <td>{yearlyBreakdown.outstandingLoanPlusInterest}</td>
                            <td>{yearlyBreakdown.studentLoanPayment}</td>
                            <td>{yearlyBreakdown.outstandingLoanEndOfYear}</td>
                            <td>{yearlyBreakdown.salaryIncrease}</td>
                        </tr>
                        )
            })}
        </tbody>
        </Table>
         <br/>
        <br/>
        <br/>
        </div>

)
}